import React, { useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';


import PermMediaIcon from '@mui/icons-material/PermMedia';
import { motion } from 'framer-motion';
import axiosInstance from '../axiosInstance/axiosInstance';

const AddCountry = ({ onClose, setIsAddCountryVisible }) => {
    const countryImageInputRef = useRef(null);
    const bannerImageInputRef = useRef(null);
    const flagImageInputRef = useRef(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);
    const [countryFileName, setCountryFileName] = useState('No file chosen');
    const [bannerFileName, setBannerFileName] = useState('No file chosen');
    const [flagFileName, setFlagFileName] = useState('No file chosen');
    const [inputs, setInputs] = useState([{ id: Date.now(), value: '' }]);
    const [countryName, setCountryName] = useState('');
    const [rank, setRank] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [flag, setFlag] = useState('');
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [whatsapp, setWhatsapp] = useState('');




    const uploadImage = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axiosInstance.post('home/api/v1/upload-file/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }

            });

            return response.data.image_url;

        } catch (error) {
            console.error(`Error uploading ${type}:`, error);
            return null;
        }
    };




    const handleImageButtonClick = (inputRef, setFileName) => () => {
        inputRef.current.value = null;
        inputRef.current.click();
    };



    const handleImageChange = (inputRef, setFileName, type) => async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            const fileUrl = await uploadImage(file, type);
            if (fileUrl) {
                setImageUrl(fileUrl);
            }
        }
    };

    const handleFlagimageChange = (inputRef, setFileName, type) => async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            const fileUrl = await uploadImage(file, type);
            if (fileUrl) {
                setFlag(fileUrl); // Update with the URL returned from the API
            }
        }
    };

    const handleBannerimageChange = (inputRef, setFileName, type) => async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            const fileUrl = await uploadImage(file, type);
            if (fileUrl) {
                setBannerImageUrl(fileUrl);
            }
        }
    };


    const handleBannerImageUrlChange = (event) => {
        setBannerImageUrl(event.target.value);
    };
    // Handlers for input fields
    const addInputField = () => {
        setInputs([...inputs, { id: Date.now(), value: '' }]);
    };

    const handleInputChange = (id, event) => {
        setInputs(inputs.map(input =>
            input.id === id ? { ...input, value: event.target.value } : input
        ));
    };

    const handleCountryNameChange = (event) => {
        setCountryName(event.target.value);
    };
    const handleWhatsappUrlChange = (event) => {
        setWhatsapp(event.target.value);
    };

    const handleRankChange = (event) => {
        setRank(event.target.value);
    };

    const handleImageUrlChange = (event) => {
        setImageUrl(event.target.value);
    };

    const handleFlagChange = (event) => {
        setFlag(event.target.value);
    };


    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('name', countryName);
            formData.append('image', imageUrl); // Use the URL from state
            formData.append('rank', rank);
            formData.append('whatsapp_url', whatsapp);

            formData.append('flag', flag); // Use the URL from state
            formData.append('banner_image', bannerImageUrl); // Use the URL from state

            const uspList = inputs.map(input => input.value);
            formData.append('usps', JSON.stringify(uspList));
            formData.append('is_active', true);

            const response = await axiosInstance.post('kanban/api/v1/countries/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 201) {
                setShowAlert(true);
                setIsAddCountryVisible(false);
                setTimeout(() => setShowAlert(false), 5000);
            }
        } catch (error) {
            console.error('Error saving the country:', error);
            setShowError(true);
            setTimeout(() => setShowError(false), 5000);
        }
    };


    const hidden = () => {
        setIsAddCountryVisible(false);
    };
    const removeInputField = (id) => {
        setInputs(inputs.filter(input => input.id !== id));
    };

    return (
        <div>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <motion.div
                className="p-6 z-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className="absolute z-10 top-[-50px] w-full flex justify-center ">
                    <div className="shadow-xl w-[700px] bg-white rounded-md">
                        <div className='bg-black flex items-center justify-center'>
                            <span className="text-2xl font-bold px-6 py-6 text-white">
                                Add Country
                            </span>
                        </div>
                        <div className='px-4 py-4 pt-8'>

                            <div className='flex justify-between mb-4 '>
                                <div className="">
                                    <label
                                        htmlFor="country-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Country Name
                                    </label>
                                    <input
                                        id="country-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        placeholder="Enter text"
                                        value={countryName}
                                        onChange={handleCountryNameChange}
                                    />
                                </div>
                                <div className="">
                                    <label
                                        htmlFor="rank-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Rank
                                    </label>
                                    <input
                                        id="rank-input"
                                        type="text"
                                        value={rank}
                                        onChange={handleRankChange}
                                        className="border border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        placeholder="Enter text"
                                    />
                                </div>

                            </div>
                            <div className='flex justify-between mb-4'>
                                <div className="">
                                    <label
                                        htmlFor="image-url-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        You can Add Flag URL
                                    </label>
                                    <input
                                        id="image-url-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md mb-4 w-[320px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        placeholder="Enter text"
                                        value={flag}
                                        onChange={handleFlagChange}
                                    />
                                </div>

                                <div>
                                    <label
                                        htmlFor="flag-image-upload"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        OR Drop Flag image here
                                    </label>
                                    <div className=' bg-black w-[320px]  p-1.5 rounded-md' onClick={handleImageButtonClick(flagImageInputRef, setFlag)}>
                                        <div className='flex items-center justify-center'>
                                            {flagFileName === 'No file chosen' ? (
                                                <div className='flex items-center gap-2'>

                                                    <button type="button" className="rounded-md text-[12px] text-white">
                                                        Drag and Drop Flag Image
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='flex items-center gap-2'>

                                                    <div className=' text-[12px] text-white'>
                                                        {flagFileName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="flag-image-upload"
                                            type="file"
                                            ref={flagImageInputRef}
                                            onChange={handleFlagimageChange(flagImageInputRef, setFlagFileName, 'flag_image')}
                                            style={{ display: 'none' }}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className='flex justify-between mb-4'>
                                <div className="">
                                    <label
                                        htmlFor="image-url-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        You can Add Country URL
                                    </label>
                                    <input
                                        id="image-url-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md mb-4 w-[320px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        placeholder="Enter text"
                                        value={imageUrl}
                                        onChange={handleImageUrlChange}
                                    />
                                </div>


                                <div>
                                    <label
                                        htmlFor="country-image-upload"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        OR Drop Country image here
                                    </label>
                                    <div className=' w-[320px] bg-black p-1.5 rounded-md' onClick={handleImageButtonClick(countryImageInputRef, setImageUrl)}>
                                        <div className='flex items-center justify-center'>
                                            {countryFileName === 'No file chosen' ? (
                                                <div className='flex items-center gap-2'>

                                                    <button type="button" className="rounded-md text-[12px] text-white">
                                                        Drag and Drop Country Image
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='flex items-center gap-2'>

                                                    <div className=' text-[12px] text-white'>
                                                        {countryFileName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="country-image-upload"
                                            type="file"
                                            ref={countryImageInputRef}
                                            onChange={handleImageChange(countryImageInputRef, setCountryFileName, 'country')}
                                            style={{ display: 'none' }}
                                        />
                                    </div>


                                </div>

                            </div>
                            <div className=' flex gap-6 mb-4'>
                                <div className="">
                                    <label
                                        htmlFor="image-url-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        You can Add Banner image url
                                    </label>
                                    <input
                                        id="image-url-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md mb-4 w-[320px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        placeholder="Enter URL"
                                        value={bannerImageUrl}
                                        onChange={handleBannerImageUrlChange}
                                    />
                                </div>
                                <div className=''>
                                    <label
                                        htmlFor="banner-image-upload"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        OR  Drop Banner image here
                                    </label>
                                    <div className=' w-[320px] bg-black  p-1.5 rounded-md' onClick={handleImageButtonClick(bannerImageInputRef, setBannerImageUrl)}>
                                        <div className='flex items-center justify-center'>
                                            {bannerFileName === 'No file chosen' ? (
                                                <div className='flex items-center gap-2'>

                                                    <button type="button" className="rounded-md text-[12px] text-white">
                                                        Drag and Drop Banner Image
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='flex items-center gap-2'>

                                                    <div className=' text-[12px] text-white'>
                                                        {bannerFileName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="banner-image-upload"
                                            type="file"
                                            ref={bannerImageInputRef}
                                            onChange={handleBannerimageChange(bannerImageInputRef, setBannerFileName, 'banner_image')}
                                            style={{ display: 'none' }}
                                        />
                                    </div>

                                </div>

                            </div>


                            <div className="mb-4">
                                <label
                                    htmlFor="country-input"
                                    className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                >
                                    WhatsApp URL
                                </label>
                                <input
                                    id="country-input"
                                    type="text"
                                    className="border border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    placeholder="Enter text"
                                    value={whatsapp}
                                    onChange={handleWhatsappUrlChange}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="banner-image-upload"
                                    className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                >
                                    USP
                                </label>
                                <div className=" flex flex-wrap flex-row ">
                                    <div className='flex flex-row flex-wrap gap-6'>
                                        {inputs.map((input) => (
                                            <div key={input.id} className="flex items-center flex-row   flex-wrap">
                                                <input
                                                    type="text"
                                                    value={input.value}
                                                    onChange={(event) => handleInputChange(input.id, event)}
                                                    className="border w-[300px] border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                    placeholder="Enter USP"
                                                />
                                                <DeleteIcon
                                                    className='cursor-pointer ml-2'
                                                    style={{ fontSize: '18px', color: 'red' }}
                                                    onClick={() => removeInputField(input.id)}
                                                />
                                            </div>

                                        ))}

                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={addInputField}
                                            className="text-black- text-[12px]"
                                        >
                                            <AddIcon />
                                        </button>

                                    </div>


                                </div>
                            </div>

                            {showError &&
                                <div className='py-4'>
                                    <Alert severity="error">something went wrong . Please fill the form again.</Alert>
                                </div>


                            }



                            {
                                showAlert &&
                                <div className='py-4'>
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert severity="success">This is a success Alert.</Alert>

                                    </Stack>

                                </div>

                            }
                            <div className="flex justify-end gap-2 px-10 p ">
                                <button
                                    onClick={hidden}
                                    className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className="bg-black text-white font-bold py-1 px-4 text-[13px] rounded-sm inter-light"
                                >
                                    AddCountry
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default AddCountry;






