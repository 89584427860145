
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EducationLevelDropdown from './EducationLevelDropdown';
import EducationDurationDropdown from './EducationDurationDropdown';
import EducationModeDropdown from './EducationModeDropdown';
import POFDropdown from './POFDropdown';
import LanguageRequirement from './LanguageRequirement';
import StudyGapDropdown from './StudyGapDropdown';
import { format } from 'date-fns';
import axiosInstance from '../axiosInstance/axiosInstance';
import CountryCourseDropdown from './CountryCourseDropdown';
import UniversityDropdown from './UniversityDropdown';
import { motion } from 'framer-motion';

const AddCourse = ({ onClose, setCourseAddVisible }) => {
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [Date, setDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [educationStatus, setEduactionStatus] = useState(null);
    const [educationOpen, setEducationOpen] = useState(null)
    const [educationLevels, setEducationLevels] = useState([]);
    const [educationModeOptions,setEducationModeOptions]= useState([]);
    const[countryDropOptions,setCountryDropOptions]=useState([]);
    const [universityDropOptions,setUniversityDropOptions]=useState([])

    const [countryStatus, setCountryStatus] = useState(null)
    const [countryOpen, setCountryOpen] = useState(null)
    const [duration, setDuration] = useState(null);
    const [durationOpen, setDurationOpen] = useState(null);
    const [educationModestatus, setEducationModeStatus] = useState(null)
    const [educationModOpen, setEducationModeOpen] = useState(null)
    const [studyGapstatus, setStudyGapStatus] = useState(null)
    const [studyGapOpen, setStudyGapOpen] = useState(null)
    const [pofMethodStatus, setPofMethodStatus] = useState(null)
    const [pofMethodOpen, setPofMethodOpen] = useState(null)
    const [languagestatus, setLanguageStatus] = useState(null)
    const [languageOpen, setLanguageOpen] = useState(null)
    const [universityOpen, setUniversityOpen] = useState(null)
    const [universityStatus, setUniversityStatus] = useState(null)
    const [countryOptions, setCountryOptions] = useState([]);
    const [universityOptions, setUniversityOptions] = useState([]);
    const [uniqueIdCounter, setUniqueIdCounter] = useState(1); // Manage unique ID counter in state



    const [formData, setFormData] = useState({
        country: null,
        university: null,
        name: "",
        campus: "",
        course_duration: "",
        education_level:null,
        fee_per_year: "",
        POF: "",
        POF_mode: "",
        language: "",
        score: "",
        intake: "",
        discription: "",
        expiry_date: "",
        
        admission_requirements: [
            {
                education_duration: "",
                education_mode:null,
                minimum_mark: "",
                study_gap: "",

            }
        ],

        tags: [] 


    });
    const handleAddMore = () => {
        setFormData((prev) => ({
            ...prev,
            admission_requirements: [
                ...prev.admission_requirements,
                {
                    education_duration: "",
                    education_mode: "",
                    minimum_mark: "",
                    study_gap: ""
                }
            ]
        }));
    };


    useEffect(() => {
        const fetchEducationLevels = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/education_level_dropdown/');
                if (response.status === 200) {
                    setEducationLevels(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchEducationLevels();
    }, []);
    useEffect(() => {
        const fetchEducationMode = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/education_mode_dropdown/');
                if (response.status === 200) {
                    setEducationModeOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchEducationMode();
    }, []);
    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axiosInstance.get('kanban/api/v1/country_dropdown/');
                if (response.status === 200) {
                    setCountryDropOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchCountry();
    }, []);

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/university-dropdown/');
                if (response.status === 200) {
                    setUniversityDropOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchCountry();
    }, []);


    const handleRequirementChange = (index, field, value) => {
        const updatedRequirements = [...formData.admission_requirements];
        updatedRequirements[index][field] = value;
        setFormData((prev) => ({
            ...prev,
            admission_requirements: updatedRequirements
        }));
    };
    const toggleDropdown = (index, type) => {
        const updatedRequirements = [...formData.admission_requirements];
        updatedRequirements[index][type] = !updatedRequirements[index][type];
        setFormData((prev) => ({
            ...prev,
            admission_requirements: updatedRequirements
        }));
    };



   

    const handleTagChange = (e) => {
        setNewTag(e.target.value);
    };


    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && newTag.trim()) {
            if (newTag === null || newTag === undefined) {
                console.error("Invalid tag input: newTag is null or undefined");
                return;
            }

            const tagExists = tags.some(tag => tag.toLowerCase() === newTag.trim().toLowerCase());
            if (!tagExists) {
                const newTagString = newTag.trim().toLowerCase(); // Just a string

                setTags(prevTags => [...prevTags, newTagString]);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    tags: [...prevFormData.tags, newTagString]
                }));

                setNewTag(''); // Clear input field
            }
        }
    };



    const handleTagDelete = (tagToDelete) => {
        setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));
        setFormData(prevFormData => ({
            ...prevFormData,
            tags: prevFormData.tags.filter(tag => tag !== tagToDelete)
        }));
    };



    const handleChange = (field) => (option) => {
        setFormData((prev) => ({ ...prev, [field]: option }));
    };

    const handleEducationlevel = (option) => {
        setEduactionStatus(option.name);
        setFormData({ ...formData, education_level: option.id });
    };


   






    const handleCountry = (option) => {
        setCountryStatus(option.name); // This updates the UI with the selected country name
        setFormData({ ...formData, country: option.id }); // Ensure only the country ID is sent to the API
    };

    const handleUniversity = (option) => {
        setUniversityStatus(option.name); // This updates the UI with the selected university name
        setFormData({ ...formData, university: option.id }); // Ensure only the university ID is sent to the API
    };

    const handleEducationDuration = (option) => {
        setDuration(option);
        setFormData({ ...formData, education_duration: option });
    };
    const handleEducationMode = (option) => {
        setEducationModeStatus(option);
        setFormData({ ...formData, education_mode: option });
    };
    const handlePofMethod = (option) => {
        setPofMethodStatus(option);
        setFormData({ ...formData, POF_mode: option });
    };
    const handleLanguage = (option) => {
        setLanguageStatus(option);
        setFormData({ ...formData, language: option });
    };
    const handleStudyGap = (option) => {
        setStudyGapStatus(option);
        setFormData({ ...formData, study_gap: option });
    };
    const toggleEducationlevelDropdown = () => {
        setEducationOpen((prevState) => !prevState);
    };
    const toggleEducationDuration = () => {
        setDurationOpen((prevState) => !prevState);
    };
    const toggleCountryDropdown = () => {
        setCountryOpen(prev => !prev);
    };

    const toggleUniversityDropdown = () => {
        setUniversityOpen(prev => !prev);
    };
    const toggleEducationMode = () => {
        setEducationModeOpen((prevState) => !prevState);
    };
    const togglePofMethod = () => {
        setPofMethodOpen((prevState) => !prevState);
    };
    const toggleLanguage = () => {
        setLanguageOpen((prevState) => !prevState);
    };
    const toggleStudyGap = () => {
        setStudyGapOpen((prevState) => !prevState);
    };
    const handleDateChange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date to "YYYY-MM-DD"
        setDate(date);
        setFormData({ ...formData, intake: formattedDate });
    };
    const handleExpiryDateChange = (date) => {
        const formattedExpiryDate = format(date, 'yyyy-MM-dd');
        setExpiryDate(date);
        setFormData({ ...formData, expiry_date: formattedExpiryDate });
    };
    const cleanRequirementData = (requirement) => {
        const { durationOpen, educationModeOpen, studyGapOpen, ...cleanedRequirement } = requirement;
        return cleanedRequirement;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clean up each requirement object in the form data
        const cleanedRequirements = formData.admission_requirements.map(cleanRequirementData);

        // Prepare the final form data object
        const cleanedFormData = {
            ...formData,
            admission_requirements: cleanedRequirements,
        };

        try {
            const response = await axiosInstance.post('home/api/v1/courses/', cleanedFormData);

            if (response.status === 201) {
                console.log('Custom status 210 received: Course added successfully.');
                setCourseAddVisible(false);
            } else {
                console.log('Course added successfully:', response.data);
                // Handle other status codes as needed
            }
            // Handle success, e.g., show a message, clear the form, etc.
        } catch (error) {
            console.error('There was an error adding the course:', error);
            // Handle error, e.g., show an error message.
        }
    };
    const hidden = () => {
        setCourseAddVisible(false)
    }

    return (
        <div>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <>
                <motion.div
                    className="p-6 z-10"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >



                    <div className="flex items-center justify-center absolute  top-[-80px] left-[50px] right-[100px] overflow-y-auto">
                        <div className=" bg-white  shadow-xl max-h-[1000px] overflow-y-auto ">
                            <div className="bg-black flex items-center justify-center">
                                <span className="text-2xl font-bold px-6 py-6 text-white">
                                    Add Course
                                </span>
                            </div>
                            <div className=' px-4 py-4'>






                                <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Country
                                        </label>
                                        <CountryCourseDropdown
                                            options={countryDropOptions}
                                            selectedOption={countryStatus} // Pass countryStatus here
                                            onSelect={handleCountry}
                                            toggleDropdown={toggleCountryDropdown}
                                            countryOpen={countryOpen}
                                            setCountryOpen={setCountryOpen}
                                        />

                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px] inter-semilight text-gray-600  mb-2"
                                        >
                                            University</label>
                                        <UniversityDropdown
                                            options={universityDropOptions}
                                            selectedOption={universityStatus} // Pass universityStatus here
                                            onSelect={handleUniversity}
                                            toggleDropdown={toggleUniversityDropdown}
                                            universityOpen={universityOpen}
                                            setUniversityOpen={setUniversityOpen}
                                        />
                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Campus
                                        </label>
                                        <input
                                            id="input-field"
                                            type="text"
                                            value={formData.campus}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter text"
                                            onChange={(e) => setFormData({ ...formData, campus: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row justify-between pb-4 gap-2">
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Course
                                        </label>
                                        <input
                                            id="input-field"
                                            type="text"
                                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter text"
                                            value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                        />
                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Education Level
                                        </label>

                                        <EducationLevelDropdown
                                            options={educationLevels}  // Pass the array directly
                                            selectedOption={educationStatus} // This will store the selected education level name
                                            onSelect={handleEducationlevel}
                                            toggleDropdown={toggleEducationlevelDropdown}
                                            educationOpen={educationOpen}
                                            setEducationOpen={setEducationOpen}
                                        />


                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Course Duration
                                        </label>
                                        <input
                                            id="input-field"
                                            type="text"
                                            value={formData.course_duration}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter text"
                                            onChange={(e) => setFormData({ ...formData, course_duration: e.target.value })}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className=" text-lg inter-bold">Admission Requirements</span>
                                    </div>
                                  
                                    {formData.admission_requirements.map((requirement, index) => (
                                        <div key={index}>
                                            <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                                <div className="mb-4">
                                                    <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                        Education Duration
                                                    </label>
                                                    <EducationDurationDropdown
                                                        options={['16 years Education(UG+PG)/4 UG)', '15 years Education ( UG/3 Diploma)', '15 years (UG only)', '+2']}
                                                        selectedOption={requirement.education_duration}
                                                        onSelect={(option) => handleRequirementChange(index, 'education_duration', option)}
                                                        toggleDropdown={() => toggleDropdown(index, 'durationOpen')}
                                                        durationOpen={requirement.durationOpen}
                                                        setDurationOpen={() => toggleDropdown(index, 'durationOpen')}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                        Education Mode
                                                    </label>
                                                    <EducationModeDropdown
                                                        options={educationModeOptions}
                                                        selectedOption={requirement.education_mode}
                                                        onSelect={(option) => handleRequirementChange(index, 'education_mode', option.id)}
                                                        toggleDropdown={() => toggleDropdown(index, 'educationModeOpen')}
                                                        educationModOpen={requirement.educationModeOpen}
                                                        setEducationModeOpen={() => toggleDropdown(index, 'educationModeOpen')}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                        Minimum Marks in Percentage
                                                    </label>
                                                    <input
                                                        id="input-field"
                                                        type="text"
                                                        value={requirement.minimum_mark}
                                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                        placeholder="Enter text"
                                                        onChange={(e) => handleRequirementChange(index, 'minimum_mark', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                    Study Gap Up to(Gap in years)
                                                </label>
                                                <StudyGapDropdown
                                                    options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+']}
                                                    selectedOption={requirement.study_gap}
                                                    onSelect={(option) => handleRequirementChange(index, 'study_gap', option)}
                                                    toggleDropdown={() => toggleDropdown(index, 'studyGapOpen')}
                                                    studyGapOpen={requirement.studyGapOpen}
                                                    setStudyGapOpen={() => toggleDropdown(index, 'studyGapOpen')}
                                                />
                                            </div>
                                        </div>
                                    ))}





                                    <div className="py-4">
                                        <div
                                            className="border border-black rounded-full px-2 py-1 text-[12px] w-40 inter-light flex items-center justify-center cursor-pointer"
                                            onClick={handleAddMore}
                                        >
                                            Add More
                                            <AddIcon style={{ fontSize: '17' }} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className="inter-bold text-lg ">Study Expenses</span>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Fees year in INR
                                            </label>
                                            <input
                                                id="input-field"
                                                type="number"
                                                value={formData.fee_per_year}
                                                className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Enter text"
                                                onChange={(e) => setFormData({ ...formData, fee_per_year: e.target.value })}
                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Proof of Fund
                                            </label>
                                            <input
                                                id="input-field"
                                                type="number"
                                                value={formData.POF}
                                                className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Enter text"
                                                onChange={(e) => setFormData({ ...formData, POF: e.target.value })}
                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                POF Method
                                            </label>

                                            <POFDropdown
                                                options={['Show Money', 'Credit Card Limit', 'Blocked Account']}
                                                selectedOption={pofMethodStatus}
                                                onSelect={handlePofMethod}
                                                toggleDropdown={togglePofMethod}
                                                pofMethodOpen={pofMethodOpen}
                                                setPofMethodOpen={setPofMethodOpen}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className="inter-bold text-lg">Language Requirements</span>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Language Requirements
                                            </label>

                                            <LanguageRequirement
                                                options={['NIL', 'IELTS']}
                                                selectedOption={languagestatus}
                                                onSelect={handleLanguage}
                                                toggleDropdown={toggleLanguage}
                                                languageOpen={languageOpen}
                                                setLanguageOpen={setLanguageOpen}



                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Score
                                            </label>
                                            <input
                                                id="input-field"
                                                type="text"
                                                value={formData.score}
                                                className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Enter text"
                                                onChange={(e) => setFormData({ ...formData, score: e.target.value })}
                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Intake
                                            </label>
                                            <DatePicker

                                                selected={Date}
                                                onChange={handleDateChange}
                                                dateFormat="yyyy/MM/dd"
                                                placeholderText="Select Intake Date"
                                                className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row gap-4 pb-4 ">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="textarea-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Description
                                        </label>
                                        <textarea
                                            id="textarea-field"
                                            className="hide-scrollbar border border-gray-300 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 resize-none w-72 text-[12px]"
                                            placeholder="Description"
                                            rows={5}
                                            value={formData.discription}
                                            onChange={(e) => setFormData({ ...formData, discription: e.target.value })}
                                        />
                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Expiry Date                            </label>

                                        <DatePicker

                                            selected={expiryDate}
                                            onChange={handleExpiryDateChange}
                                            placeholderText="Select a date"
                                            dateFormat="MM/dd/yyyy"
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className="inter-bold text-md text-lg">Create Tags Here</span>
                                    </div>
                                    <div className="flex flex-wrap gap-2 mb-2">
                                        {tags.map((tag) => (
                                            <div
                                                key={tag} // Use the tag string as the key
                                                className="border border-gray-300 rounded-full text-white bg-black inter-semilight  px-2 py-1 text-[12px]  flex items-center justify-between"
                                            >
                                                <span>{tag}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleTagDelete(tag)} // Use the tag string directly
                                                    className="ml-2 text-red-500"
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        ))}
                                        <div className="relative flex items-center">
                                            <input
                                                type="text"
                                                value={newTag}
                                                onChange={handleTagChange}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Add Tag Here "
                                                className="border border-gray-600 inter-light rounded-full px-2 py-1 text-[12px]   outline-none"
                                            />
                                            <div className="absolute  top-1/2 right-2 transform -translate-y-1/2">
                                                <AddIcon style={{ fontSize: '17px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-4 flex gap-2">
                                    <button className="bg-black text-white font-bold py-1 px-4 text-[13px] rounded-sm inter-light "
                                        onClick={handleSubmit}>
                                        Add Course
                                    </button>
                                    <button className="bg-black text-white font-bold py-1 px-4 text-[13px] rounded-sm inter-light "
                                        onClick={hidden}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </>

        </div>
    )
}

export default AddCourse