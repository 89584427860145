import React, { useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DatePicker from 'react-datepicker';
import { motion } from 'framer-motion';
import axiosInstance from '../axiosInstance/axiosInstance';

const AddUniversity = ({ onClose, setIsAddUniversityVisible }) => {
    const bannerFileInputRef = useRef(null);
    const logoFileInputRef = useRef(null);
    const [bannerFileName, setBannerFileName] = useState('No file chosen');
    const [logoFileName, setLogoFileName] = useState('No file chosen');
    const [startDate, setStartDate] = useState(null);
    const [studentReviewUrls, setStudentReviewUrls] = useState([{ id: Date.now() }]);
    const [universityTourVideos, setUniversityTourVideos] = useState([{ id: Date.now() }]);
    const [campusLocations, setCampusLocations] = useState('');
    const [universityName, setUniversityName] = useState('');
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [pofAmount, setPofAmount] = useState('');
    const [language, setLanguage] = useState('');
    const [mark, setMark] = useState('');

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axiosInstance.post('home/api/v1/upload-file/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return response.data.image_url;
        } catch (error) {
            console.error('Error uploading image:', error);
            return null;
        }
    };

    const handleImageButtonClick = (inputRef) => () => {
        inputRef.current.value = null;
        inputRef.current.click();
    };

    const handleBannerImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setBannerFileName(file.name);
            const fileUrl = await uploadImage(file);
            if (fileUrl) {
                setBannerImageUrl(fileUrl);
            }
        }
    };

    const handleLogoImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoFileName(file.name);
            const fileUrl = await uploadImage(file);
            if (fileUrl) {
                setLogoUrl(fileUrl);
            }
        }
    };

    const addStudentReviewField = () => {
        setStudentReviewUrls([...studentReviewUrls, { id: Date.now() }]);
    };

    const addUniversityTourField = () => {
        setUniversityTourVideos([...universityTourVideos, { id: Date.now() }]);
    };
    const formatDate = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('name', universityName);
        formData.append('location', campusLocations);
        const pofAmountNumber = parseFloat(pofAmount) || 0;
        formData.append('pof_amount', pofAmountNumber);
        formData.append('language_pref', language);
        formData.append('minimum_mark', mark);
        formData.append('intake', formatDate(startDate));
        formData.append('banner_image', bannerImageUrl);
        formData.append('logo_image', logoUrl);
        formData.append('is_active', true);

       

       
        formData.append('review_video_url', JSON.stringify(studentReviewUrls.map((url) => url.url || '')));
        formData.append('tour_video_url', JSON.stringify(universityTourVideos.map((video) => video.url || '')));

        try {
            await axiosInstance.post('home/api/v1/universities/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('University added successfully');
            setIsAddUniversityVisible(false);
        } catch (error) {
            console.error('Error adding university:', error);
            alert('An error occurred while adding the university');
        }
    };

    const hidden = () => {
        setIsAddUniversityVisible(false);
    };

    return (
        <div>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <>
                <motion.div
                    className="p-6 z-10"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="flex items-center justify-center absolute  top-4">
                        <div className="shadow-xl rounded-md bg-white     ">
                            <div className="bg-black flex items-center justify-center">
                                <span className="text-2xl font-bold px-6 py-6 text-white">
                                    Add University
                                </span>
                            </div>
                            <div className="px-4 py-4 pt-8">
                                <div className="flex flex-col md:flex-row justify-between gap-8 pb-2">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="university-name"
                                            className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                        >
                                            University Name
                                        </label>
                                        <input
                                            id="university-name"
                                            type="text"
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter text"
                                            value={universityName}
                                            onChange={(e) => setUniversityName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="campus-locations"
                                            className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                        >
                                            University Campus Locations (comma-separated)
                                        </label>
                                        <input
                                            id="campus-locations"
                                            type="text"
                                            value={campusLocations}
                                            onChange={(e) => setCampusLocations(e.target.value)}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter locations"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="banner-image-url"
                                            className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                        >
                                            University Banner URL
                                        </label>
                                        <input
                                            id="banner-image-url"
                                            type="text"
                                            value={bannerImageUrl}
                                            onChange={(e) => setBannerImageUrl(e.target.value)}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="logo-image-url"
                                            className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                        >
                                            University Logo URL
                                        </label>
                                        <input
                                            id="logo-image-url"
                                            type="text"
                                            value={logoUrl}
                                            onChange={(e) => setLogoUrl(e.target.value)}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        />
                                    </div>
                                </div>

                                {/* Banner and Logo Image Upload */}
                                <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                            Banner Image
                                        </label>
                                        <button
                                            type="button"
                                            className="bg-gray-800 text-white px-4 py-2 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px]"
                                            onClick={handleImageButtonClick(bannerFileInputRef)}
                                        >
                                            Upload Banner Image
                                        </button>
                                        <input
                                            type="file"
                                            ref={bannerFileInputRef}
                                            onChange={handleBannerImageChange}
                                            className="hidden"
                                        />
                                        <p className="text-gray-600 text-[12px]">{bannerFileName}</p>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-[12px]  text-gray-600 mb-2 inter-semilight">
                                            Logo Image
                                        </label>
                                        <button
                                            type="button"
                                            className="bg-gray-800 text-white px-4 w-full md:w-[200px] lg:w-[300px] py-2 rounded-md text-[12px]"
                                            onClick={handleImageButtonClick(logoFileInputRef)}
                                        >
                                            Upload Logo Image
                                        </button>
                                        <input
                                            type="file"
                                            ref={logoFileInputRef}
                                            onChange={handleLogoImageChange}
                                            className="hidden"
                                        />
                                        <p className="text-gray-600 text-[12px]">{logoFileName}</p>
                                    </div>
                                </div>

                                {/* Additional Fields */}
                                <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="pof-amount"
                                            className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                        >
                                            Proof of Funds Amount
                                        </label>
                                        <input
                                            id="pof-amount"
                                            type="text"
                                            value={pofAmount}
                                            onChange={(e) => setPofAmount(e.target.value)}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="language-preference"
                                            className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                        >
                                            Language Preference
                                        </label>
                                        <input
                                            id="language-preference"
                                            type="text"
                                            value={language}
                                            onChange={(e) => setLanguage(e.target.value)}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="minimum-mark"
                                            className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                        >
                                            Minimum Mark
                                        </label>
                                        <input
                                            id="minimum-mark"
                                            type="text"
                                            value={mark}
                                            onChange={(e) => setMark(e.target.value)}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        />
                                    </div>

                                    {/* Start Date Picker */}
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                            Start Date
                                        </label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>

                                </div>


                                {/* Student Reviews */}
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                        Student Reviews
                                    </label>
                                    {studentReviewUrls.map((review, index) => (
                                        <div key={review.id} className="flex items-center mb-2">
                                            <input
                                                type="text"
                                                value={review.url || ''}
                                                onChange={(e) =>
                                                    setStudentReviewUrls(
                                                        studentReviewUrls.map((r, i) =>
                                                            i === index ? { ...r, url: e.target.value } : r
                                                        )
                                                    )
                                                }
                                                className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Review URL"
                                            />
                                            <button
                                                type="button"
                                                className="ml-2  text-red-500 px-2 py-1 rounded-md text-[12px]"
                                                onClick={() => {
                                                    setStudentReviewUrls(
                                                        studentReviewUrls.filter((_, i) => i !== index)
                                                    );
                                                }}
                                            >
                                               <DeleteIcon style={{fontSize:'18px'}}/>
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="flex items-center text-black text-[12px]"
                                        onClick={addStudentReviewField}
                                    >
                                        <AddIcon fontSize="small" />
                                       
                                    </button>
                                </div>

                              
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                        University Tour Videos
                                    </label>
                                    {universityTourVideos.map((video, index) => (
                                        <div key={video.id} className="flex items-center  mb-2">
                                            <input
                                                type="text"
                                                value={video.url || ''}
                                                onChange={(e) =>
                                                    setUniversityTourVideos(
                                                        universityTourVideos.map((v, i) =>
                                                            i === index ? { ...v, url: e.target.value } : v
                                                        )
                                                    )
                                                }
                                                className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Tour Video URL"
                                            />
                                            <button
                                                type="button"
                                                className="ml-2  text-red-500 px-2 py-1 rounded-md text-[12px]"
                                                onClick={() => {
                                                    setUniversityTourVideos(
                                                        universityTourVideos.filter((_, i) => i !== index)
                                                    );
                                                }}
                                            >
                                            <DeleteIcon style={{fontSize:'18px'}}/>
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="flex items-center text-black text-[12px]"
                                        onClick={addUniversityTourField}
                                    >
                                        <AddIcon fontSize="small" />
                                        
                                    </button>
                                </div>

                                <div className="flex justify-end gap-2">
                                    <button
                                        type="button"
                                         className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                         className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                                        onClick={hidden}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </>
        </div>
    );
};

export default AddUniversity;






