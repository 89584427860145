
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import SearchIcon from '@mui/icons-material/Search';
import AddCourse from './AddCourse';
import { AnimatePresence } from 'framer-motion';
import UpdateCourseForm from './UpdateCourseForm';

const CourseListingPage = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [nextUrl, setNextUrl] = useState('home/api/v1/courses/?limit=10&offset=0'); // Fixed URL
    const [isCourseAddVisible, setCourseAddVisible] = useState(false);
    const [isCourseUpdateVisible, setCourseUpdateVisible] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);

    useEffect(() => {
        fetchCourses(nextUrl); // Fetch courses on component mount
    }, [nextUrl]); // Add nextUrl as dependency

    const fetchCourses = async (url) => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(url);
            const { results = [], next = null } = response.data; // Destructure with default values

            if (Array.isArray(results)) {
                setCourses(results);
                setNextUrl(next);
                setHasMore(!!next);
            } else {
                console.error('Invalid response format: ', response.data);
                setHasMore(false); // Stop fetching if response format is incorrect
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleCourseAddModal = () => {
        setCourseAddVisible(prev => !prev);
    };

    const toggleUpdateCourseModal = (courseId) => {
        setSelectedCourseId(courseId);
        setCourseUpdateVisible(prev => !prev);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                if (hasMore && !loading) {
                    fetchCourses(nextUrl); 
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasMore, loading, nextUrl]);

    return (
        <div className="h-screen flex flex-col relative">
            <div className="flex flex-col gap-4 overflow-y-auto">
                {/* Search Bar */}
                <div className='flex gap-4 justify-center '>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 pl-3 text-gray-400 text-sm flex items-center">
                            <SearchIcon />
                        </span>
                        <input
                            type="text"
                            className="inter-font block w-96 text-[12px] rounded-md pr-12 pl-10 py-2.5 bg-gray-100 border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide"
                            placeholder="Search Course here..."
                        />
                    </div>
                    <div onClick={toggleCourseAddModal}>
                        <button
                            type="button"
                            className="inter-font bg-black text-white text-[12px] font-bold px-4 py-2 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
                        >
                            Add Course
                        </button>
                    </div>
                </div>
                <div>
                    <table className='w-full mx-auto table-auto'>
                        <thead>
                            <tr className='bg-gray-100 pb-4'>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-gray-400">Country</td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-gray-400">University</td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-gray-400">Course</td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-gray-400">Duration</td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-gray-400">Fees (INR)</td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-gray-400">Expiry Date</td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-[11px] text-gray-400">Intake</td>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr><td colSpan="7" className="text-center py-4">Loading...</td></tr>
                            ) : courses.length === 0 ? (
                                <tr><td colSpan="7" className="text-center py-4">No data available</td></tr>
                            ) : (
                                courses.map(course => (
                                    <tr key={course.id} className='shadow-md'>
                                        <td className="inter-font px-4 py-3 text-[12px] text-black">{course.country?.name || 'N/A'}</td>
                                        <td className="inter-font px-4 py-3 text-[12px] text-black" onClick={() => toggleUpdateCourseModal(course.id)}>{course.university?.name || 'N/A'}</td>
                                        <td className="inter-font px-4 py-3 text-[12px] text-black">{course.name}</td>
                                        <td className="inter-font px-4 py-3 text-[12px] text-black">{course.course_duration}</td>
                                        <td className="inter-font px-4 py-3 text-[12px] text-black">{course.fee_per_year}</td>
                                        <td className="inter-font px-4 py-3 text-[12px] text-black">{course.expiry_date}</td>
                                        <td className="inter-font px-4 py-3 text-[12px] text-black">{course.intake}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <AnimatePresence>
                {isCourseAddVisible && (
                    <AddCourse onClose={toggleCourseAddModal} setCourseAddVisible={setCourseAddVisible} />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {isCourseUpdateVisible && (
                    <UpdateCourseForm universityId={selectedCourseId} onClose={() => setCourseUpdateVisible(false)} setCourseUpdateVisible={setCourseUpdateVisible} />
                )}
            </AnimatePresence>
        </div>
    );
};

export default CourseListingPage;

