import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EducationLevelDropdown from './EducationLevelDropdown';
import EducationDurationDropdown from './EducationDurationDropdown';
import EducationModeDropdown from './EducationModeDropdown';
import POFDropdown from './POFDropdown';
import LanguageRequirement from './LanguageRequirement';
import StudyGapDropdown from './StudyGapDropdown';
import { format } from 'date-fns';
import axiosInstance from '../axiosInstance/axiosInstance';
import CountryCourseDropdown from './CountryCourseDropdown';
import UniversityDropdown from './UniversityDropdown';
import { motion } from 'framer-motion';
import UniversityDropdownUpdate from './UniversityDropdownUpdate';
import CountryCourseDropdownUpdate from './CountryCourseDropdownUpdate';
import EducationModeupdateDropdown from './EducationModeupdateDropdown';

const UpdateCourseForm = ({ universityId, onClose, setCourseUpdateVisible }) => {

    const [formData, setFormData] = useState({
        country: null,
        university: null,
        name: "",
        campus: "",
        course_duration: "",
        education_level: "",
        fee_per_year: "",
        POF: "",
        POF_mode: "",
        language: "",
        score: "",
        intake: "",
        discription: "",
        expiry_date: "",
        education_duration: "",
        education_mode: "",
        minimum_mark: "",
        study_gap: "",
        tags: [],
        admission_requirements: []
    });
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [Date, setDate] = useState(null);
    const [admission_requirements, setAdmission_requirements] = useState(null)


    const [educationOpen, setEducationOpen] = useState(null)
    const [educationStatus, setEduactionStatus] = useState(null);

    const [educationLevels, setEducationLevels] = useState([]);
    const [educationModeOptions, setEducationModeOptions] = useState([]);
    const [selectedEducationMode, setSelectedEducationMode] = useState(null);

    const [countryDropOptions, setCountryDropOptions] = useState([]);
    const [universityDropOptions, setUniversityDropOptions] = useState([])

    const [countryStatus, setCountryStatus] = useState(null)
    const [countryOpen, setCountryOpen] = useState(null)
    const [duration, setDuration] = useState(null);
    const [durationOpen, setDurationOpen] = useState(null);
    const [educationModestatus, setEducationModeStatus] = useState(null)
    const [educationModOpen, setEducationModeOpen] = useState(null)
    const [studyGapstatus, setStudyGapStatus] = useState(null)
    const [studyGapOpen, setStudyGapOpen] = useState(null)
    const [pofMethodStatus, setPofMethodStatus] = useState(null)
    const [pofMethodOpen, setPofMethodOpen] = useState(null)
    const [languagestatus, setLanguageStatus] = useState(null)
    const [languageOpen, setLanguageOpen] = useState(null)
    const [universityOpen, setUniversityOpen] = useState(null)
    const [universityStatus, setUniversityStatus] = useState(null)
    const [countryOptions, setCountryOptions] = useState([]);
    const [universityOptions, setUniversityOptions] = useState([]);
    const [expiryDate, setExpiryDate] = useState(null);





    useEffect(() => {
        const fetchEducationLevels = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/education_level_dropdown/');
                if (response.status === 200) {
                    setEducationLevels(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchEducationLevels();
    }, []);
    useEffect(() => {
        const fetchEducationMode = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/education_mode_dropdown/');
                if (response.status === 200) {
                    setEducationModeOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchEducationMode();
    }, []);
    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axiosInstance.get('kanban/api/v1/country_dropdown/');
                if (response.status === 200) {
                    setCountryDropOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchCountry();
    }, []);

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axiosInstance.get('home/api/v1/university-dropdown/');
                if (response.status === 200) {
                    setUniversityDropOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchCountry();
    }, []);


    const handleTagChange = (e) => {
        setNewTag(e.target.value);
    };









    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && newTag.trim()) {
            const tagText = newTag.trim().toLowerCase();

            // Check if the tag already exists
            if (tagText && !tags.some(tag => tag.tag.toLowerCase() === tagText)) {
                // Create a new tag object
                const newTagObject = { tag: tagText };

                // Update state
                setTags(prevTags => [...prevTags, newTagObject]);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    tags: [...prevFormData.tags, { tag: tagText }] // Append new tag as object
                }));

                setNewTag(''); // Clear input field
            }
        }
    };






    const handleChange = (field) => (option) => {
        setFormData((prev) => ({ ...prev, [field]: option }));
    };

    const handleEducationlevel = (option) => {
        setEduactionStatus(option.name);
        setFormData({ ...formData, educationlevel: option.id });
    };
    const handleCountry = (option) => {
        console.log("Selected Country:", option);
        setCountryStatus(option); // Update status to reflect the selected option
        setFormData(prevFormData => ({
            ...prevFormData,
            country: option // Store the entire option object, if needed
        }));
    };

    const handleUniversity = (option) => {
        console.log("Selected University:", option);
        setUniversityStatus(option); // Update status to reflect the selected option
        setFormData(prevFormData => ({
            ...prevFormData,
            university: option // Store the entire option object, if needed
        }));
        toggleUniversityDropdown();
    };



    const handleEducationDuration = (option) => {
        setDuration(option);
        setFormData({ ...formData, education_duration: option });
    };
    const handleEducationMode = (option) => {
        setEducationModeStatus(option);
        setFormData({ ...formData, education_mode: option });
    };
    const handlePofMethod = (option) => {
        setPofMethodStatus(option);
        setFormData({ ...formData, POF_mode: option });
    };
    const handleLanguage = (option) => {
        setLanguageStatus(option);
        setFormData({ ...formData, language: option });
    };
    const handleStudyGap = (option) => {
        setStudyGapStatus(option);
        setFormData({ ...formData, study_gap: option });
    };
    const toggleEducationlevelDropdown = () => {
        setEducationOpen((prevState) => !prevState);
    };
    const toggleEducationDuration = () => {
        setDurationOpen((prevState) => !prevState);
    };
    const toggleCountryDropdown = () => {
        setCountryOpen(prev => !prev);
    };

    const toggleUniversityDropdown = () => {
        setUniversityOpen(prev => !prev);
    };
    const toggleEducationMode = () => {
        setEducationModeOpen((prevState) => !prevState);
    };
    const togglePofMethod = () => {
        setPofMethodOpen((prevState) => !prevState);
    };
    const toggleLanguage = () => {
        setLanguageOpen((prevState) => !prevState);
    };
    const toggleStudyGap = () => {
        setStudyGapOpen((prevState) => !prevState);
    };
    const handleDateChange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setDate(date);
        setFormData({ ...formData, intake: formattedDate });
    };
    const handleExpiryDateChange = (date) => {
        const formattedExpiryDate = format(date, 'yyyy-MM-dd');
        setExpiryDate(date);
        setFormData({ ...formData, expiry_date: formattedExpiryDate });
    };



    useEffect(() => {
        // Fetch course details on component mount
        axiosInstance.get(`home/api/v1/course_detail/${universityId}/`)
            .then(response => {
                const data = response.data;
                console.log(data, "helo data");

                setFormData(prevFormData => ({
                    ...prevFormData,
                    ...data, // Update formData with the fetched data
                    admission_requirements: data.admission_requirements || [] // Update admission requirements
                }));
                setTags(response.data.tags || []);
                setAdmission_requirements(response.data.admission_requirements || [])
                setCountryStatus(response.data.country); // Assuming API provides country object
                setUniversityStatus(response.data.university);
                setEduactionStatus(response.data.education_level); // assuming the API provides the selected education level
                setDuration(response.data.education_duration); // assuming the API provides the selected duration
                // assuming the API provides the selected mode
                // assuming the API provides the selected study gap
                setPofMethodStatus(response.data.POF_mode);
                setLanguageStatus(response.data.language)
                setDate(response.data.intake)
                setExpiryDate(response.data.expiry_date)
                setDuration(response.data.admission_requirements.education_duration)
                setStudyGapStatus(response.data.admission_requirements.study_gap)
                setSelectedEducationMode(data.admission_requirements[0]?.education_mode ? 
                    educationModeOptions.find(option => option.name === data.admission_requirements[0].education_mode) 
                    : null
                );


                // assuming the API provides the selected POF method
            })
            .catch(error => {
                console.error("There was an error fetching the course details!", error);
            });
    }, [universityId,educationModeOptions]);
    const handleSelectEducationMode = (option) => {
        setSelectedEducationMode(option);
        setEducationModeOpen(false);
        // Update formData with the selected education mode
        setFormData(prevFormData => ({
            ...prevFormData,
            admission_requirements: prevFormData.admission_requirements.map(req =>
                req.id === formData.admission_requirements[0]?.id
                    ? { ...req, education_mode: option.name }
                    : req
            )
        }));
    };






    const handleTagDelete = (tagToDelete) => {
        // Remove the tag from the local state
        setTags(prevTags => prevTags.filter(tag => tag.tag !== tagToDelete));

        // Update formData by removing the tag
        setFormData(prevFormData => ({
            ...prevFormData,
            tags: prevFormData.tags.filter(tagObj => tagObj.tag !== tagToDelete)
        }));
    };

    const handleAdmissionRequirementChange = (index, field, value) => {
        const updatedRequirements = [...formData.admission_requirements];
        updatedRequirements[index] = { ...updatedRequirements[index], [field]: value };
        setFormData(prevFormData => ({ ...prevFormData, admission_requirements: updatedRequirements }));
    };

    // const handleRequirementChange = (index, field, value) => {
    //     const updatedRequirements = [...formData.admission_requirements];
    //     updatedRequirements[index][field] = value;
    //     setFormData((prev) => ({
    //         ...prev,
    //         admission_requirements: updatedRequirements
    //     }));
    // };


    const handleRequirementChange = (id, field, value) => {
        setFormData(prevFormData => {
            const updatedRequirements = prevFormData.admission_requirements.map((requirement, index) =>
                index === id ? { ...requirement, [field]: value } : requirement
            );
            return { ...prevFormData, admission_requirements: updatedRequirements };
        });
    };
    const toggleDropdown = (index, type) => {
        const updatedRequirements = [...formData.admission_requirements];
        updatedRequirements[index][type] = !updatedRequirements[index][type];
        setFormData((prev) => ({
            ...prev,
            admission_requirements: updatedRequirements
        }));
    };

    const educationModeMapping = educationModeOptions.reduce((acc, option) => {
        acc[option.name] = option.id; // Assuming `name` is the string and `id` is the ID
        return acc;
    }, {});



    const handleSubmit = async (e) => {
        e.preventDefault();
        const tagStrings = formData.tags.map(tagObj => tagObj.tag);




        // Prepare the data to send
        const dataToSend = {
            name: formData.name || "",
            campus: formData.campus || "",
            course_duration: formData.course_duration || "",

            fee_per_year: formData.fee_per_year || "",
            POF: formData.POF || "",
            POF_mode: formData.POF_mode || "",
            language: formData.language || "",
            score: formData.score || "",
            intake: formData.intake || "",
            description: formData.discription || "",
            expiry_date: formData.expiry_date || "",
            tags: tagStrings, // Include only tag strings here
            country: formData.country ? formData.country.id : null, // Ensure ID is sent
            university: formData.university ? formData.university.id : null, // Ensure ID is sent
            education_level: formData.education_level ? formData.education_level.id : null,
            // Ensure ID is sent
            admission_requirements: formData.admission_requirements.map(req => ({
                id: req.id,
                education_duration: req.education_duration,
                minimum_mark: req.minimum_mark,
                study_gap: req.study_gap,
                education_mode: educationModeMapping[req.education_mode] || req.education_mode // Map to ID if it exists in the mapping
            }))
        };

        try {

            console.log("Data to send:", dataToSend);


            const response = await axiosInstance.put(`home/api/v1/course_detail/${universityId}/`, dataToSend);
            console.log("Course updated successfully:", response.data);
            onClose();
            setCourseUpdateVisible(false);
        } catch (error) {
            console.error("There was an error updating the course!", error);
        }
    };



    const hidden = () => {
        setCourseUpdateVisible(false)
    }

    return (
        <div>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <>
                <motion.div
                    className="p-6 z-10"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >



                    <div className="flex items-center justify-center absolute  top-[-80px] left-[50px] right-[100px] overflow-y-auto">
                        <div className=" bg-white shadow-xl max-h-[1000px] overflow-y-auto ">
                            <div className="bg-black flex items-center justify-center">
                                <span className="text-2xl font-bold px-6 py-6 text-white">
                                    Update Course
                                </span>
                            </div>
                            <div className=' px-4 py-4'>






                                <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Country
                                        </label>
                                        <CountryCourseDropdownUpdate
                                            options={countryDropOptions}
                                            selectedOption={countryStatus}
                                            onSelect={handleCountry}
                                            toggleDropdown={toggleCountryDropdown}
                                            countryOpen={countryOpen}
                                            setCountryOpen={setCountryOpen}
                                        />

                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px] inter-semilight text-gray-600  mb-2"
                                        >
                                            University
                                        </label>
                                        <UniversityDropdownUpdate
                                            options={universityDropOptions}
                                            selectedOption={universityStatus}
                                            onSelect={handleUniversity}
                                            toggleDropdown={toggleUniversityDropdown}
                                            universityOpen={universityOpen}
                                            setUniversityOpen={setUniversityOpen}
                                        />
                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Campus
                                        </label>
                                        <input
                                            id="input-field"
                                            type="text"
                                            value={formData.campus}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter text"
                                            onChange={(e) => setFormData({ ...formData, campus: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row justify-between pb-4 gap-2">
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Course
                                        </label>
                                        <input
                                            id="input-field"
                                            type="text"
                                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter text"
                                            value={formData.name}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                        />
                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Education Level
                                        </label>

                                        <EducationLevelDropdown
                                            options={educationLevels}
                                            selectedOption={educationStatus}
                                            onSelect={handleEducationlevel}
                                            toggleDropdown={toggleEducationlevelDropdown}
                                            educationOpen={educationOpen}

                                            setEducationOpen={setEducationOpen}



                                        />

                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Course Duration
                                        </label>
                                        <input
                                            id="input-field"
                                            type="text"
                                            value={formData.course_duration}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter text"
                                            onChange={(e) => setFormData({ ...formData, course_duration: e.target.value })}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className=" text-lg inter-bold">Admission Requirements</span>
                                    </div>
                                    {formData.admission_requirements.map((requirement, id) => (
                                        <div key={id}>
                                            <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                                <div className="mb-4">
                                                    <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                        Education Duration
                                                    </label>
                                                    <EducationDurationDropdown
                                                        options={['16 years Education(UG+PG)/4 UG)', '15 years Education ( UG/3 Diploma)', '15 years (UG only)', '+2']}
                                                        selectedOption={requirement.education_duration}
                                                        onSelect={(option) => handleRequirementChange(id, 'education_duration', option)}
                                                        toggleDropdown={() => toggleDropdown(id, 'durationOpen')}
                                                        durationOpen={requirement.durationOpen}
                                                        setDurationOpen={() => toggleDropdown(id, 'durationOpen')}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                        Education Mode
                                                    </label>
                                                    {/* <EducationModeupdateDropdown
                                                        options={educationModeOptions}


                                                        selectedOption={requirement.education_mode}
                                                        onSelect={(option) => handleRequirementChange(id, 'education_mode', option.id)}
                                                        toggleDropdown={() => toggleDropdown(id, 'educationModeOpen')}
                                                        educationModOpen={requirement.educationModeOpen}
                                                        setEducationModeOpen={() => toggleDropdown(id, 'educationModeOpen')}
                                                    /> */}
                                                     <EducationModeupdateDropdown
                options={educationModeOptions}
                selectedOption={selectedEducationMode}
                onSelect={handleSelectEducationMode}
                toggleDropdown={toggleEducationMode}
                educationModOpen={educationModOpen} // Current state of the dropdown
                setEducationModeOpen={setEducationModeOpen} // Function to directly set the dropdown state
            />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                        Minimum Marks in Percentage
                                                    </label>
                                                    <input
                                                        id="input-field"
                                                        type="text"
                                                        value={requirement.minimum_mark}
                                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                        placeholder="Enter text"
                                                        onChange={(e) => handleRequirementChange(id, 'minimum_mark', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="input-field" className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">
                                                    Study Gap Up to(Gap in years)
                                                </label>
                                                <StudyGapDropdown
                                                    options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+']}
                                                    selectedOption={requirement.study_gap}
                                                    onSelect={(option) => handleRequirementChange(id, 'study_gap', option)}
                                                    toggleDropdown={() => toggleDropdown(id, 'studyGapOpen')}
                                                    studyGapOpen={requirement.studyGapOpen}
                                                    setStudyGapOpen={() => toggleDropdown(id, 'studyGapOpen')}
                                                />
                                            </div>
                                        </div>
                                    ))}



                                </div>

                                <div className="py-4">
                                    <div className="border border-black rounded-full px-2 py-1 text-[12px] w-40 inter-light flex items-center justify-center ">
                                        <button
                                            type="button"
                                            onClick={() => setFormData(prev => ({
                                                ...prev,
                                                admission_requirements: [...prev.admission_requirements, {
                                                    // Generate a temporary id
                                                    education_duration: '',
                                                    education_mode: '',
                                                    minimum_mark: '',
                                                    study_gap: ''
                                                }]
                                            }))}

                                        >
                                            Add More   <AddIcon style={{ fontSize: '12px' }} />
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className="inter-bold text-lg ">Study Expenses</span>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Fees year in INR
                                            </label>
                                            <input
                                                id="input-field"
                                                type="number"
                                                value={formData.fee_per_year}
                                                className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Enter text"
                                                onChange={(e) => setFormData({ ...formData, fee_per_year: e.target.value })}
                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Proof of Fund
                                            </label>
                                            <input
                                                id="input-field"
                                                type="number"
                                                value={formData.POF}
                                                className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Enter text"
                                                onChange={(e) => setFormData({ ...formData, POF: e.target.value })}
                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                POF Method
                                            </label>
                                            {/* <input
                            id="input-field"
                            type="text"
                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                            placeholder="Enter text"
                        /> */}
                                            <POFDropdown
                                                options={['Show Money', 'Credit Card Limit', 'Blocked Account']}
                                                selectedOption={pofMethodStatus}
                                                onSelect={handlePofMethod}
                                                toggleDropdown={togglePofMethod}
                                                pofMethodOpen={pofMethodOpen}
                                                setPofMethodOpen={setPofMethodOpen}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className="inter-bold text-lg">Language Requirements</span>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between gap-2 pb-4">
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Language Requirements
                                            </label>
                                            {/* <input
                            id="input-field"
                            type="text"
                            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                            placeholder="Enter text"
                        /> */}
                                            <LanguageRequirement
                                                options={['NIL', 'IELTS']}
                                                selectedOption={languagestatus}
                                                onSelect={handleLanguage}
                                                toggleDropdown={toggleLanguage}
                                                languageOpen={languageOpen}
                                                setLanguageOpen={setLanguageOpen}



                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Score
                                            </label>
                                            <input
                                                id="input-field"
                                                type="text"
                                                value={formData.score}
                                                className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                                placeholder="Enter text"
                                                onChange={(e) => setFormData({ ...formData, score: e.target.value })}
                                            />
                                        </div>
                                        <div classname="mb-4">
                                            <label
                                                htmlfor="input-field"
                                                className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                            >
                                                Intake
                                            </label>
                                            <DatePicker

                                                selected={Date}
                                                onChange={handleDateChange}
                                                dateFormat="yyyy/MM/dd"
                                                placeholderText="Select Intake Date"
                                                className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row gap-4 pb-4 ">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="textarea-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Description
                                        </label>
                                        <textarea
                                            id="textarea-field"
                                            className="hide-scrollbar border border-gray-300 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 resize-none w-72 text-[12px]"
                                            placeholder="Description"
                                            rows={5}
                                            value={formData.discription}
                                            defaultValue={""}
                                            onChange={(e) => setFormData({ ...formData, discription: e.target.value })}
                                        />
                                    </div>
                                    <div classname="mb-4">
                                        <label
                                            htmlfor="input-field"
                                            className="block text-gray-700  text-[12px]   text-gray-600 mb-2 inter-semilight"
                                        >
                                            Expiry Date                            </label>

                                        <DatePicker

                                            selected={expiryDate}
                                            onChange={handleExpiryDateChange}
                                            placeholderText="Select a date"
                                            dateFormat="MM/dd/yyyy"
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="pb-4">
                                        <span className="inter-bold text-md text-lg">Create Tags Here</span>
                                    </div>
                                    <div className="flex flex-wrap gap-2 mb-2">
                                        {tags.map((tag, index) => (
                                            <div
                                                key={index} // Use index or a unique identifier for the key
                                                className="border border-gray-300 rounded-full px-2 py-1 text-[12px] bg-black text-white font-semilight flex items-center justify-between"
                                            >
                                                <span>{tag.tag}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleTagDelete(tag.tag)}
                                                    className="ml-2 text-red-500"
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        ))}
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={newTag}
                                                onChange={handleTagChange}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Add Tags Here"
                                                className="border border-gray-300 rounded-full inter-light px-2 py-1 text-[12px] w-40 outline-none"
                                            />
                                            <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                                                <AddIcon style={{ fontSize: '17px' }} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="py-4 flex gap-2">
                                    <button className="bg-black text-white font-bold py-1 px-4 text-[13px] rounded-sm inter-light "
                                        onClick={handleSubmit}>
                                        Update
                                    </button>
                                    <button className="bg-black text-white font-bold py-1 px-4 text-[13px] rounded-sm inter-light "
                                        onClick={hidden}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </>


        </div >
    )
}

export default UpdateCourseForm