import React, { useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { AnimatePresence } from 'framer-motion';
import AddUniversity from './AddUniversity';
import axiosInstance from '../axiosInstance/axiosInstance';
import UpdateUniversity from './UpdateUniversity';

const UniversityListingPage = () => {
  const [isAddUniversityVisible, setIsAddUniversityVisible] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [visibility, setVisibility] = useState({});
  const [isUpdateUniversityVisible, setIsUpdateUniversityVisible] = useState(false);
  const [selectedUniversityId, setSelectedUniversityId] = useState(null);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await axiosInstance.get('home/api/v1/universities/');
        setUniversities(response.data);

        const initialVisibility = response.data.reduce((acc, university) => {
          acc[university.id] = university.is_active;
          return acc;
        }, {});
        setVisibility(initialVisibility);
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
    };

    fetchUniversities();
  }, []);

  const toggleAddUniversityModal = () => {
    setIsAddUniversityVisible(prev => !prev);
  };

  const toggleUpdateUniversityModal = (universityId) => {
    setSelectedUniversityId(universityId);
    setIsUpdateUniversityVisible(prev => !prev);
  };

  const toggleVisibility = async (index, universityId, currentVisibility) => {
    const updatedVisibility = !currentVisibility;
    
    try {
      const updatedUniversities = [...universities];
      updatedUniversities[index] = { ...updatedUniversities[index], is_active: updatedVisibility };
      setUniversities(updatedUniversities);
      setVisibility(prev => ({ ...prev, [universityId]: updatedVisibility }));

      await axiosInstance.patch(`home/api/v1/university_detail/${universityId}/`, {
        is_active: updatedVisibility,
      });
    } catch (error) {
      console.error('Error toggling visibility:', error);

      // Revert the change if there was an error
      const revertedUniversities = [...universities];
      revertedUniversities[index] = { ...revertedUniversities[index], is_active: !updatedVisibility };
      setUniversities(revertedUniversities);
      setVisibility(prev => ({ ...prev, [universityId]: !updatedVisibility }));
    }
  };

  return (
    <div>
      <div className='flex flex-col gap-10'>
        {/* Search bar */}
        <div className='flex gap-4 justify-center'>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 text-gray-400 text-sm flex items-center">
              <SearchIcon />
            </span>
            <input
              type="text"
              className="inter-font block w-96 text-[12px] rounded-md pr-12 pl-10 py-2.5 bg-gray-100 border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide"
              placeholder="Search University here..."
            />
          </div>
          <div onClick={toggleAddUniversityModal}>
            <button
              type="button"
              className="inter-font bg-black text-white text-[12px] font-bold px-4 py-2 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
            >
              Add University
            </button>
          </div>
        </div>
        <div>
          <table className='w-[800px]'>
            <thead>
              <tr className='bg-black'>
                <th className="inter-font px-4 py-3 text-center text-[11px] text-white">University</th>
                <th className="inter-font px-4 py-3 text-center text-[11px] text-white">Status</th>
                <th className="inter-font px-4 py-3 text-center text-[11px] text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {universities.map((university, index) => (
                <tr key={university.id} className="shadow-md">
                  <td className="inter-light px-4 py-3 flex items-center gap-2">
                    <img src={university.logo_image} alt={university.name} className="w-8 h-8 object-cover rounded-full" />
                    <div className='text-[12px]'>{university.name}</div>
                  </td>
                  <td className="inter-light px-4 py-3 text-center">
                    {visibility[university.id] ?
                      <span className="ml-2 inter-semilight text-green-600 bg-green-100 px-4 py-1 rounded-sm text-[12px]">Active</span>
                      :
                      <span className="ml-2 inter-semilight text-red-600 bg-red-100 px-3 py-1 rounded text-[12px]">Inactive</span>
                    }
                  </td>
                  <td className="inter-light px-4 py-3 text-center">
                    <div className='flex justify-center gap-2'>
                      <div onClick={() => toggleVisibility(index, university.id, university.is_active)}>
                        {university.is_active ? (
                          <VisibilityIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
                        ) : (
                          <VisibilityOffIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
                        )}
                      </div>
                      <div onClick={() => toggleUpdateUniversityModal(university.id)}>
                        <EditIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AnimatePresence>
        {isAddUniversityVisible && (
          <AddUniversity onClose={toggleAddUniversityModal} setIsAddUniversityVisible={setIsAddUniversityVisible} />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isUpdateUniversityVisible && (
          <UpdateUniversity
            universityId={selectedUniversityId}
            setIsUpdateUniversityVisible={setIsUpdateUniversityVisible}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default UniversityListingPage;





